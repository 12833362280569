<template>
    <div></div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { ref } from 'vue'
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
    name: "EmailVerification",
     setup() {
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        
        // Sending mutation and pushing directly to login screen. 
        // TODO Add handling for error scenarios. Expired link, invalid link, etc
       store.dispatch("VerifyEmail", route.params.token).then(response => {
            let signupUrl=window.location.origin+'/register';
            let success= response.data.verifyEmailMutation.success;
            let message=(success)?'Your email address has successfully been verified. Please log in to contine.':'Your email verification link has expired. Please <a class="underline" href="'+signupUrl+'">sign up</a> again.' ;
            
            router.push({ name: "Login",params: {success,message} })
        });
    }

}
</script>
